export const MastodonIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    shape-rendering="crispEdges"
    viewBox="0 -0.5 64 64"
    width="64"
    height="64"
  >
    <path
      stroke="currentColor"
      d="M4,0h56M4,1h56M4,2h56M4,3h56M0,4h8M56,4h8M0,5h8M56,5h8M0,6h8M56,6h8M0,7h8M56,7h8M0,8h8M56,8h8M0,9h8M56,9h8M0,10h8M56,10h8M0,11h8M56,11h8M0,12h4M16,12h12M36,12h12M60,12h4M0,13h4M16,13h12M36,13h12M60,13h4M0,14h4M16,14h12M36,14h12M60,14h4M0,15h4M16,15h12M36,15h12M60,15h4M0,16h4M16,16h32M60,16h4M0,17h4M16,17h32M60,17h4M0,18h4M16,18h32M60,18h4M0,19h4M16,19h32M60,19h4M0,20h4M12,20h8M28,20h8M44,20h8M60,20h4M0,21h4M12,21h8M28,21h8M44,21h8M60,21h4M0,22h4M12,22h8M28,22h8M44,22h8M60,22h4M0,23h4M12,23h8M28,23h8M44,23h8M60,23h4M0,24h4M12,24h8M28,24h8M44,24h8M60,24h4M0,25h4M12,25h8M28,25h8M44,25h8M60,25h4M0,26h4M12,26h8M28,26h8M44,26h8M60,26h4M0,27h4M12,27h8M28,27h8M44,27h8M60,27h4M0,28h4M12,28h8M28,28h8M44,28h8M60,28h4M0,29h4M12,29h8M28,29h8M44,29h8M60,29h4M0,30h4M12,30h8M28,30h8M44,30h8M60,30h4M0,31h4M12,31h8M28,31h8M44,31h8M60,31h4M0,32h4M12,32h8M44,32h8M60,32h4M0,33h4M12,33h8M44,33h8M60,33h4M0,34h4M12,34h8M44,34h8M60,34h4M0,35h4M12,35h8M44,35h8M60,35h4M0,36h4M12,36h8M44,36h8M60,36h4M0,37h4M12,37h8M44,37h8M60,37h4M0,38h4M12,38h8M44,38h8M60,38h4M0,39h4M12,39h8M44,39h8M60,39h4M0,40h4M56,40h8M0,41h4M56,41h8M0,42h4M56,42h8M0,43h4M56,43h8M0,44h4M48,44h16M0,45h4M48,45h16M0,46h4M48,46h16M0,47h4M48,47h16M0,48h8M20,48h44M0,49h8M20,49h44M0,50h8M20,50h44M0,51h8M20,51h44M0,52h8M24,52h40M0,53h8M24,53h40M0,54h8M24,54h40M0,55h8M24,55h40M0,56h12M44,56h20M0,57h12M44,57h20M0,58h12M44,58h20M0,59h12M44,59h20M4,60h56M4,61h56M4,62h56M4,63h56"
    />
  </svg>
);
